<template>
  <div :style="`font-family:${font},sans-serif`">
    <!-- {{this.pickings}} -->
    <div class="row justify-content-center">
      <div class="card col-sm-12 col-md-12 p-1">
        <div>
          <div>
            <b-form>
              <div class="h2" style="color: #558cef">เบิกทรัพย์สิน</div>
              <hr />
              <div>
                <validation-observer ref="observer" v-slot="{ passes }">
                  <b-row>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ทะเบียนเบิก:">
                      <template v-slot:label> ทะเบียนเบิก <span class="text-danger"> *</span> </template>
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-input
                          :state="errors.length > 0 ? false : null"
                          class="col-12"
                          id="basicInput"
                          v-model="equipment_code"
                          placeholder="AUTO"
                          disabled
                        />
                      </validation-provider>
                    </b-form-group>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label=";:">
                      <template v-slot:label> วันที่เบิก <span class="text-danger"> *</span> </template>
                      <validation-provider #default="{ errors }" rules="required">
                        <v-date-picker
                          id="example-datepicker3"
                          label="วันที่เบิก:"
                          locale="th-TH"
                          v-model="date1"
                          @update:from-page="getHeaderInputDate"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <b-form-input
                              :value="formatDate(inputValue, 'date1')"
                              v-on="inputEvents"
                              :state="errors.length > 0 ? false : null"
                            />
                          </template>
                          <template #header-title>
                            {{ headerInputDate }}
                          </template>
                        </v-date-picker>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ปีงบประมาณ:">
                      <template v-slot:label> ปีงบประมาณ <span class="text-danger"> *</span> </template>
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-input
                          maxlength="4"
                          id="basicInput"
                          :state="errors.length > 0 ? false : null"
                          v-model="budget_year"
                          placeholder="ปีงบประมาณ"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="เลขที่เอกสาร:">
                      <template v-slot:label> เลขที่เอกสาร <span class="text-danger"> </span> </template>

                      <b-form-input id="basicInput" v-model="important_number" placeholder="เลขที่เอกสาร" />
                    </b-form-group>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ประเภทหลักฐาน:">
                      <template v-slot:label> ประเภทหลักฐาน <span class="text-danger"> </span> </template>
                      <v-select placeholder="เลือกประเภทหลักฐาน" label="evidence" v-model="proof" :options="proofs" />
                    </b-form-group>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="วันที่รับ:">
                      <template v-slot:label> วันที่รับ <span class="text-danger"> *</span> </template>
                      <validation-provider #default="{ errors }" rules="required">
                        <v-date-picker
                          id="example-datepicker3"
                          label="วันที่รับ:"
                          locale="th-TH"
                          v-model="date2"
                          @update:from-page="getHeaderInputDate"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <b-form-input
                              :value="formatDate(inputValue, 'date2')"
                              v-on="inputEvents"
                              :state="errors.length > 0 ? false : null"
                            />
                          </template>
                          <template #header-title>
                            {{ headerInputDate }}
                          </template>
                        </v-date-picker>
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="กลุ่มงาน:">
                      <template v-slot:label> กลุ่มงาน <span class="text-danger"> *</span> </template>
                      <v-select
                        label="work_gid_work_gname"
                        v-model="workG"
                        :options="workGs"
                        placeholder="เลือกกลุ่มงาน"
                        @input="work_select($event)"
                      />
                    </b-form-group>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ฝ่ายงาน:">
                      <template v-slot:label> ฝ่ายงาน/ตำเเหน่ง <span class="text-danger"> *</span> </template>
                      <v-select
                        placeholder="เลือกฝ่ายงาน"
                        label="dep_id_dep_name"
                        v-model="department"
                        :options="departments"
                        @input="fullnameFromWorkgroupAndDepartment"
                      />
                    </b-form-group>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ผู้เบิก/ผู้บังคับบัญชา:">
                      <template v-slot:label> ผู้เบิก/ผู้บังคับบัญชา <span class="text-danger"> *</span> </template>
                      <validation-provider #default="{ errors }" rules="required">
                        <!-- {{req_name}} -->
                        <v-select
                          :options="alluser_user"
                          label="fullname"
                          :state="errors.length > 0 ? false : null"
                          v-model="req_name"
                          placeholder="ผู้เบิก/ผู้บังคับบัญชา:"
                        />
                      </validation-provider>
                    </b-form-group>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ผู้เบิก/ผู้บังคับบัญชา:">
                      <template v-slot:label> ผู้ขอเบิกทรัพย์สิน <span class="text-danger"> *</span> </template>
                      <validation-provider #default="{ errors }" rules="required">
                        <!-- {{req_name}} -->
                        <v-select
                          :options="alluser"
                          label="firstname"
                          :state="errors.length > 0 ? false : null"
                          v-model="req_name_"
                          placeholder="ผู้ขอเบิกทรัพย์สิน:"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-row>

                  <div class="row">
                    <b-button class="ml-2" variant="gradient-primary" v-b-modal.modal-1 @click="c_select">
                      เลือกรายการทรัพย์สิน
                    </b-button>
                    <b-button class="ml-1" variant="gradient-success" align-self="end" @click.prevent="passes(getdata)">
                      <!-- @click.prevent="passes(getdata)" -->
                      เบิกทรัพย์สิน
                    </b-button>
                  </div>
                </validation-observer>
                <b-modal
                  id="modal-1"
                  title="เลือกรายการทรัพย์สิน"
                  ok-only
                  ok-title="ตกลง"
                  size="lg"
                  @ok="addItem"
                  no-close-on-backdrop
                >
                  <div>
                    <b-form-group label-cols="3" label-cols-lg="2" label="กลุ่มงาน:">
                      <v-select
                        placeholder="เลือกกลุ่มงาน"
                        label="work_gid_work_gname"
                        v-model="workG"
                        :options="workGs"
                      />
                    </b-form-group>
                    <b-form-group label-cols="3" label-cols-lg="2" label="กลุ่มทรัพย์สิน:">
                      <v-select
                        placeholder="เลือกกลุ่มทรัพย์สิน"
                        v-model="selectedG"
                        label="e_gname"
                        :options="selectedGs"
                        @input="item_select($event)"
                      />
                    </b-form-group>
                    <b-form-group label-cols="3" label-cols-lg="2" label="ค้นหา:">
                      <b-form-input placeholder="ค้นหา" v-model="searchTermitem" />
                    </b-form-group>
                    <b-overlay :show="show" rounded="sm">
                      <vue-good-table
                        :columns="materialGroup"
                        :rows="material"
                        :search-options="{
                          enabled: true,
                          externalQuery: searchTermitem,
                        }"
                        :select-options="{
                          enabled: true,
                        }"
                        :pagination-options="{
                          enabled: true,
                          perPage: material_pageLength,
                        }"
                        @on-selected-rows-change="selectionChanged"
                      >
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field === 'image'">
                            <span>
                              <b-button
                                variant="outline-warning"
                                v-b-modal="`modal-image${props.index}`"
                                @click="get_image(props.row)"
                              >
                                <feather-icon icon="ImageIcon" class="mr-50" />
                              </b-button>
                              <!-- basic modal -->
                              <b-modal :id="`modal-image${props.index}`" title="ดูรูปภาพ" ok-only ok-title="ปิด">
                                <b-card-text class="text-center">
                                  <div v-if="image_p != null">
                                    <swiper
                                      ref="mySwiper"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="swiperOptions"
                                    >
                                      <swiper-slide v-for="image_p in image_p" :key="image_p">
                                        <b-img
                                          width="250%"
                                          height="250%"
                                          src
                                          :onerror="`fetch('${API}files/image/${image_p}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                                          class="mx-1"
                                        />
                                      </swiper-slide>
                                      <div slot="button-next" class="swiper-button-next" />
                                      <div slot="button-prev" class="swiper-button-prev" />
                                      <div slot="pagination" class="swiper-pagination" />
                                    </swiper>
                                    <!-- <div class="text-center" v-for="image_p in image_p" :key="image_p">
                                      <img
                                        width="50%"
                                        height="50%"
                                        src
                                        :onerror="`fetch('${API}files/image/${image_p}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                                        class="mx-1"
                                      />
                                    </div> -->
                                  </div>
                                  <span v-else>
                                    <div class="text-center">ไม่มีรูปภาพ</div>
                                  </span>
                                </b-card-text>
                              </b-modal>
                            </span>
                          </span>
                        </template>
                      </vue-good-table>
                    </b-overlay>
                  </div>
                </b-modal>
              </div>
            </b-form>
          </div>
        </div>
        <hr />
        <div class="h2" style="color: #558cef">รายการเบิก</div>
        <hr />
        <!-- table -->
        <b-overlay :show="show" rounded="sm">
          <vue-good-table :columns="picking" :rows="pickings" :line-numbers="true">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'delete_quantity'">
                <span>
                  <b-button @click="delete_data_value(props.index)" variant="danger">ลบ</b-button>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-overlay>
      </div>
    </div>

    <div class="row justify-content-around">
      <div class="col-sm-12 col-md-12 card p-1">
        <div class="title head">
          <p class="h2" style="color: #558cef">รายการเบิกทรัพย์สิน</p>
        </div>
        <hr />
        <!-- search input -->
        <div class="custom-search row justify-content-end">
          <b-form-group class="col-12 col-md-6">
            <div class="d-flex align-items-center">
              <label class="mr-1">ค้นหา</label>
              <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
              <div>
                <b-dropdown variant="link" toggle-class="text-decoration-none ml-1" no-caret>
                  <template v-slot:button-content>
                    <b-button variant="outline-primary"
                      ><feather-icon icon="ShareIcon" class="mr-50" />Export
                      <feather-icon icon="ChevronDownIcon" class="mr-50"
                    /></b-button>
                  </template>
                  <b-dropdown-item @click="exportExcel">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    Excel
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <b-overlay :show="show" rounded="sm">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            :line-numbers="true"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->

              <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                <b-avatar :src="props.row.avatar" class="mx-1" />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status_withdraw'">
                <div class="text-center">
                  <div v-if="props.row.status_withdraw === 'อนุมัติ'">
                    <b-button variant="success"> อนุมัติ </b-button>
                  </div>

                  <div v-else-if="props.row.status_withdraw === 'รอเบิก'">
                    <b-button variant="warning"> รอเบิก </b-button>
                  </div>
                  <div v-else>
                    <b-button variant="danger"> ไม่อนุมัติ </b-button>
                  </div>
                </div>
              </span>
              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'staff'">
                <span>
                  <div v-if="status_user == 'ผู้ใช้ทั่วไป'"></div>
                  <div v-else>
                    <b-button
                      v-b-modal="`modal-staff${props.index}`"
                      variant="outline-warning"
                      @click="cc_data(props.row)"
                    >
                      <feather-icon icon="UserPlusIcon" class="mr-50" />
                    </b-button>
                  </div>
                </span>
                <b-modal
                  :id="`modal-staff${props.index}`"
                  class="text-nowrap"
                  title="ลงชื่อผู้เจ้าหน้าที่เบิกทรัพย์สิน"
                  ok-title="บันทึก"
                  cancel-title="ยกเลิก"
                  @ok="WithdrawStaff(props.row)"
                  @cancel="cc_data(props.row)"
                  no-close-on-backdrop
                >
                  <!-- <div class="text-center">{{ props.row }}</div> -->
                  <div>
                    <!-- ผู้จ่ายวัสดุ -->

                    <b-form-group label="ผู้จ่ายทรัพย์สิน" label-for="basicInput">
                      <template v-slot:label>
                        ผู้จ่ายทรัพย์สิน
                        <span class="text-danger"> *</span>
                      </template>
                      <v-select
                        :options="alluser"
                        label="firstname"
                        placeholder="ลงชื่อผู้จ่ายทรัพย์สิน"
                        v-model="props.row.staff_name1"
                        @input="a_position(props.row.staff_name1)"
                      />
                    </b-form-group>
                    <b-form-group label="ตำเเหน่ง" label-for="basicInput">
                      <template v-slot:label> ตำเเหน่ง <span class="text-danger"> *</span> </template>
                      <!-- {{props.row}} -->
                      <div v-if="props.row.status_withdraw == 'อนุมัติ'">
                        <b-input v-model="props.row.staff1_position" placeholder="ตำเเหน่ง" />
                      </div>
                      <div v-else><b-input v-model="department1" placeholder="ตำเเหน่ง" /></div>
                    </b-form-group>
                    <b-form-group label="ลงวันที่" label-for="basicInput">
                      <template v-slot:label> ลงวันที่ <span class="text-danger"> *</span> </template>
                      <v-date-picker
                        id="example-datepicker3"
                        label="ลงวันที่:"
                        locale="th-TH"
                        v-model="date11"
                        @update:from-page="getHeaderInputDate"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <b-form-input :value="formatDate(inputValue, 'date11')" v-on="inputEvents" />
                        </template>
                        <template #header-title>
                          {{ headerInputDate }}
                        </template>
                      </v-date-picker>
                    </b-form-group>
                    <hr />
                    <!-- ผู้รับผิดชอบ/รับมอบหมายวัสดุ -->
                    <b-form-group label="ผู้รับผิดชอบ/รับมอบหมายทรัพย์สิน" label-for="basicInput">
                      <!-- {{ props.row }} -->
                      <template v-slot:label>
                        ผู้รับผิดชอบ/รับมอบหมายทรัพย์สิน
                        <span class="text-danger"> *</span>
                      </template>
                      <v-select
                        :options="alluser"
                        label="firstname"
                        v-model="props.row.staff_name3"
                        placeholder="ลงชื่อผู้รับผิดชอบ/รับมอบหมายทรัพย์สิน"
                        @input="c_position(props.row.staff_name3)"
                      />
                    </b-form-group>
                    <b-form-group label="ตำเเหน่ง" label-for="basicInput">
                      <template v-slot:label> ตำเเหน่ง <span class="text-danger"> *</span> </template>
                      <!-- {{props.row}} -->
                      <div v-if="props.row.status_withdraw == 'อนุมัติ'">
                        <b-input v-model="props.row.staff3_position" placeholder="ตำเเหน่ง" />
                      </div>
                      <div v-else><b-input v-model="department3" placeholder="ตำเเหน่ง" /></div>
                    </b-form-group>
                    <b-form-group label="ลงวันที่" label-for="basicInput">
                      <template v-slot:label> ลงวันที่ <span class="text-danger"> *</span> </template>
                      <v-date-picker
                        id="example-datepicker3"
                        label="ลงวันที่:"
                        locale="th-TH"
                        v-model="date33"
                        @update:from-page="getHeaderInputDate"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <b-form-input :value="formatDate(inputValue, 'date33')" v-on="inputEvents" />
                        </template>
                        <template #header-title>
                          {{ headerInputDate }}
                        </template>
                      </v-date-picker>
                    </b-form-group>
                    <hr />
                    <!-- ผู้สั่งจ่ายวัสดุ -->
                    <b-form-group label="ผู้สั่งจ่ายทรัพย์สิน" label-for="basicInput">
                      <template v-slot:label>
                        ผู้สั่งจ่ายทรัพย์สิน
                        <span class="text-danger"> *</span>
                      </template>
                      <v-select
                        :options="alluser"
                        label="firstname"
                        v-model="props.row.staff_name2"
                        placeholder="ลงชื่อผู้สั่งจ่ายทรัพย์สิน"
                        @input="b_position(props.row.staff_name2)"
                      />
                    </b-form-group>
                    <b-form-group label="ตำเเหน่ง" label-for="basicInput">
                      <template v-slot:label> ตำเเหน่ง <span class="text-danger"> *</span> </template>
                      <!-- {{props.row}} -->
                      <div v-if="props.row.status_withdraw == 'อนุมัติ'">
                        <b-input v-model="props.row.department" placeholder="ตำเเหน่ง" />
                      </div>
                      <div v-else><b-input v-model="department2" placeholder="ตำเเหน่ง" /></div>
                    </b-form-group>
                    <b-form-group label="ลงวันที่" label-for="basicInput">
                      <template v-slot:label> ลงวันที่ <span class="text-danger"> *</span> </template>
                      <v-date-picker
                        id="example-datepicker3"
                        label="ลงวันที่:"
                        locale="th-TH"
                        v-model="date22"
                        @update:from-page="getHeaderInputDate"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <b-form-input :value="formatDate(inputValue, 'date22')" v-on="inputEvents" />
                        </template>
                        <template #header-title>
                          {{ headerInputDate }}
                        </template>
                      </v-date-picker>
                    </b-form-group>
                  </div>
                  <div class="row justify-content-around">
                    <b-button @click="show_ids(props.row)" variant="danger">ไม่อนุมัติ</b-button>
                  </div>
                </b-modal>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-button variant="outline-warning" @click="dateField(props)">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                  </b-button>
                </span>
              </span>
              <span v-else-if="props.column.field === 'edit'">
                <div v-if="status_user == 'ผู้ใช้ทั่วไป'">
                  <div v-if="props.row.status_withdraw == 'รอเบิก'">
                    <b-button variant="outline-warning" @click="edit_data_value(props.row)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                    </b-button>
                  </div>
                </div>
                <div v-else>
                  <!-- <b-button variant="outline-warning" v-b-modal="`modal-edit${props.index}`">
                    <feather-icon icon="EditIcon" class="mr-50" />
                  </b-button> -->
                  <b-button variant="outline-warning" @click="edit_data_value(props.row)">
                    <feather-icon icon="EditIcon" class="mr-50" />
                  </b-button>
                </div>
                <!-- <span> </span> -->
                <b-modal
                  :id="`modal-edit${props.index}`"
                  title="แก้ไขรายการเบิก"
                  ok-title="ตกลง"
                  cancel-title="ยกเลิก"
                  @ok="data_edit(props.row)"
                >
                  <b-card-text>
                    <b-form-group label="ลงวันที่" label-cols="3" label-cols-lg="2">
                      <b-form-datepicker
                        locale="th-TH"
                        v-model="props.row.need_date"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: 'numeric',
                        }"
                      />
                    </b-form-group>
                    <b-form-group label-cols="3" label-cols-lg="2" label="ผู้เบิก:">
                      <b-form-input id="basicInput" v-model="props.row.req_name" placeholder="ผู้เบิก:" />
                    </b-form-group>
                    <b-form-group label-cols="3" label-cols-lg="2" label="ปีงบประมาณ:">
                      <b-form-input
                        maxlength="4"
                        id="basicInput"
                        v-model="props.row.budget_year"
                        placeholder="ปีงบประมาณ"
                      />
                    </b-form-group>
                  </b-card-text>
                </b-modal>
              </span>
              <span v-else-if="props.column.field === 'delete'">
                <div v-if="status_user == 'ผู้ใช้ทั่วไป'">
                  <div v-if="props.row.status_withdraw == 'รอเบิก'">
                    <b-button variant="outline-danger" @click="del_data(props.row.equipment_code)">
                      <feather-icon icon="Trash2Icon" class="mr-50" />
                    </b-button>
                  </div>
                  <div v-else-if="props.row.status_withdraw == 'ไม่อนุมัติ'">
                    <b-button variant="outline-danger" @click="del_data(props.row.equipment_code)">
                      <feather-icon icon="Trash2Icon" class="mr-50" />
                    </b-button>
                  </div>
                </div>
                <div v-else>
                  <span>
                    <b-button variant="outline-danger" @click="del_data(props.row.equipment_code)">
                      <feather-icon icon="Trash2Icon" class="mr-50" />
                    </b-button>
                  </span>
                </div>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['5', '10', '15', '25', '50', '100']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))"
                  />
                  <span class="text-nowrap">of {{ total }} entries</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="handlePagechange($event)"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import { BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue";
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
import { BFormFile } from "bootstrap-vue";
import { BFormSelect } from "bootstrap-vue";
import { BFormDatepicker } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import { DatePicker } from "v-calendar";

import { BImg } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { async } from "q";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,

    BImg,
    Swiper,
    SwiperSlide,
    DatePicker,
  },

  data() {
    return {
      headerInputDate: "",
      date1: new Date(),
      date2: new Date(),
      date11: new Date(),
      date22: new Date(),
      date33: new Date(),
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      },
      department_Lists: [],

      searchTermitem: "",
      equipment_code: "",
      budget_year: "",
      important_number: "",
      req_name: "",
      from: [
        {
          datein: "",
          datenow: "",
          dateneed: "",
        },
      ],
      selected: null,
      options: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Simple Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],

      proof: null,
      proofs: [],
      department: "",
      departments: [],
      workG: "",
      workGs: [],
      selectedG: "เลือกกลุ่มทรัพย์สิน",
      selectedGs: [],
      materialGroup: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "Serial Number",
          field: "serial_number",
        },
        {
          label: "ชื่อทรัพย์สิน",
          field: "e_name",
        },
        {
          label: "รูป",
          field: "image",
        },
      ],
      material: [],
      itemslist: [],
      picking: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "Serial Number",
          field: "serial_number",
        },
        {
          label: "ชื่อทรัพย์สิน",
          field: "e_name",
        },
        {
          label: "ลบ",
          field: "delete_quantity",
        },
      ],
      pickings: [],
      pickings_temp: [],
      pageLength: 25,
      currentPage: 1,
      material_pageLength: 100,
      total: "",
      dir: false,
      show: false,
      columns: [
        {
          label: "วัน-เดือน-ปี (วันที่เบิก)",
          field: "need_date_th",
        },
        {
          label: "เลขทะเบียน",
          field: "equipment_code",
        },
        {
          label: "กลุ่มงาน",
          field: "work_gname",
        },
        {
          label: "ผู้เบิก/ผู้บังคับบัญชา",
          field: "req_name",
        },
        {
          label: "สถานะทรัพย์สิน",
          field: "status_withdraw",
        },
        {
          label: "เจ้าหน้าที่",
          field: "staff",
        },
        {
          label: "ดูรายการ",
          field: "action",
        },
        {
          label: "แก้ไข",
          field: "edit",
        },
        {
          label: "ลบ",
          field: "delete",
        },
      ],
      req_name_: "",
      API: `${API}`,
      rows: [],
      searchTerm: "",
      selectMode: "multi",
      selected: [],
      listget: [],
      e_number: [],
      serial_number: [],
      addItemList: [],
      user: "",
      token: "",
      image_p: null,
      alluser: [],
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      status_user: "",
      username_user: "",
      alluser_user: [],
      department3: "",
      department1: "",
      department2: "",
      post_data: [],
      date_now1: "",
      date_now2: "",
      date_now3: "",
      equipment_code_gen: "",
      registration_number: "",
      getlog: "",
      ids: "",
      font: "",
    };
  },
  async created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    // this.checkLogout();
    const { usertype, username } = await JSON.parse(localStorage.getItem("username_type"));
    this.status_user = await usertype;
    this.username_user = await username;
    this.user = await username;
    this.material_code();
    this.work();
    this.item();
    this.gettable();
    this.get_data_table();
    this.s_evidenceTypes();
    this.departmentLists();
    this.auto_();
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  methods: {
    async checkLogout() {
      console.log("ตรวจสอบออกจากระบบ");
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(" User อยู่ในระบบ");
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
    },
    async show_ids(value) {
      console.log(value.originalIndex);

      this.ids = "ไม่อนุมัติ";
      const { access_token } = await this.access_token();
      const url = `${API}materialWithdrawStaff2/${value.equipment_code}`;
      const datas = {
        status_withdraw: "false",
      };
      console.log(datas);
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.put(url, datas, head);
      console.log(res.data);
      if (res.data.status === 200) {
        this.gettable();
        this.$bvModal.hide(`modal-staff${value.originalIndex}`);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "บันทึก",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${res.data.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async auto_() {
      const { work_gid, fullname } = await JSON.parse(localStorage.getItem("username_type"));

      const { access_token } = await this.access_token();
      const url_herd = `${API}headWorkgroup?work_gid=${work_gid}`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_heard = await axios.get(url_herd, header);

      const [user] = res_heard.data.message.data.map((el) => {
        return {
          ...el,

          work_gid: `${el.workgroup.split("-")[0].replace(" ", "")}`,
          work_gid_work_gname: `${el.workgroup}`,
          dep_id: `${el.depname.split("-")[0].replace(" ", "")}`,
          dep_id_dep_name: `${el.depname}`,
          fullname: `${el.title}${el.firstname} ${el.lastname}`,
        };
      });
      console.log(user);
      this.workG = user;
      this.department = user;
      this.req_name = user;
      this.req_name_ = fullname;
    },
    async WithdrawStaff(data) {
      console.log(data);
      console.log(this.department3);

      const { access_token } = await this.access_token();
      const url = `${API}materialWithdrawStaff2/${data.equipment_code}`;
      const datas = {
        staff_name1: data.staff_name1.firstname,
        staff1_position: this.department1 || data.staff1_position,
        date_1: this.date_now1 || this.date_1,

        staff_name2: data.staff_name2.firstname,
        department: data.department || this.department3,
        date_2: this.date_now2 || this.date_2,

        staff_name3: data.staff_name3.firstname,
        staff3_position: this.department2 || data.staff3_position,
        date_3: this.date_now3 || this.date_3,

        //status_withdraw: data.status_withdraw,
      };
      console.log(datas);
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.put(url, datas, head);
      console.log(res.data);
      if (res.data.status === 200) {
        this.gettable();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "บันทึก",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${res.data.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    getHeaderInputDate(events) {
      switch (events.month) {
        case 1:
          this.headerInputDate = "มกราคม " + (events.year + 543);
          break;
        case 2:
          this.headerInputDate = "กุมภาพันธ์ " + (events.year + 543);
          break;
        case 3:
          this.headerInputDate = "มีนาคม " + (events.year + 543);
          break;
        case 4:
          this.headerInputDate = "เมษายน " + (events.year + 543);
          break;
        case 5:
          this.headerInputDate = "พฤษภาคม " + (events.year + 543);
          break;
        case 6:
          this.headerInputDate = "มิถุนายน " + (events.year + 543);
          break;
        case 7:
          this.headerInputDate = "กรกฎาคม " + (events.year + 543);
          break;
        case 8:
          this.headerInputDate = "สิงหาคม " + (events.year + 543);
          break;
        case 9:
          this.headerInputDate = "กันยายน " + (events.year + 543);
          break;
        case 10:
          this.headerInputDate = "ตุลาคม " + (events.year + 543);
          break;
        case 11:
          this.headerInputDate = "พฤศจิกายน " + (events.year + 543);
          break;
        case 12:
          this.headerInputDate = "ธันวาคม " + (events.year + 543);
          break;
        default:
          this.headerInputDate = "ข้อมูลผิดพลาด";
          break;
      }
      // console.log(this.headerInputDate);
    },
    formatDate(date, val) {
      //   console.log(val);
      let formatDate = "";
      let dete_val = "";
      if (date != null) {
        const _y = date.split("/");
        const _date = parseInt(_y[2]) + 543;
        formatDate = `${_y[0]}/${_y[1]}/${_date}`;
        dete_val = _y[2] + "-" + _y[1] + "-" + _y[0];
        switch (val) {
          case "date1":
            this.from.datein = dete_val;
            break;
          case "date2":
            this.from.datenow = dete_val;
            break;
          case "date11":
            this.date_now1 = dete_val;
            break;
          case "date22":
            this.date_now2 = dete_val;
            break;
          case "date33":
            this.date_now3 = dete_val;
            break;
        }
      } else {
        formatDate = "-";
      }
      if (formatDate.toString() == "/undefined/NaN") {
        formatDate = "";
      }
      return formatDate;
    },
    c_select() {
      if (this.equipment_code_gen === "" || this.equipment_code_gen === "true") {
        this.equipment_code_gen = "true";
        this.registration_number = "";
      } else {
        this.equipment_code_gen = "false";
        // this.registration_number = '';
      }

      console.log(this.equipment_code_gen);
      console.log(this.registration_number);
      const pickings = this.pickings.length;
      if (pickings === 0) {
      } else {
        let pickings = this.pickings;
        let material = this.material;
        console.log(pickings);
        let res = material.filter((el) => {
          return !pickings.find((element) => {
            return element.e_number == el.e_number;
          });
        });

        this.material = res;
      }
    },
    cc_data(value) {
      console.log(value);
      if (value.status_withdraw === "อนุมัติ") {
        this.date11 = new Date(value.date_1).toString();
        this.date22 = new Date(value.date_2).toString();
        this.date33 = new Date(value.date_3).toString();
      }

      // this.date_now2 = value.date_2 || new Date();
      // this.date_now3 = value.date_3 || new Date();
    },
    delete_data_value(value) {
      console.log(value);
      this.material.push(this.pickings[value]);
      this.pickings = this.pickings.filter((el, index) => index != value);
      this.pickings_temp = this.pickings_temp.filter((el, index) => index != value);
      console.log(this.pickings);
      console.log(this.pickings_temp);
    },
    async edit_data_value(value) {
      this.registration_number = value.equipment_code;
      console.log(this.registration_number);
      this.equipment_code_gen = "false";

      this.date1 = new Date(value.req_date).toString();
      this.date2 = new Date(value.action_date).toString();
      const { access_token } = await this.access_token();
      const url = `${API}withdrawHistoryList?equipment_code=${value.equipment_code}`;
      const url_work_gid = `${API}departments?work_gid=${value.work_gid}`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      const res_work_gid = await axios.get(url_work_gid, header);

      const { dep_name } = res_work_gid.data.message.data.find((el) => el.dep_id === value.dep_id);
      console.log(dep_name);
      console.log(value);
      this.req_name_ = value.requistion_name;
      this.pickings = res.data.message.data;
      this.post_data = value;
      this.equipment_code = value.equipment_code;
      this.req_name = value.req_name;
      this.budget_year = value.budget_year;
      this.from.datein = this.date1;
      this.important_number = value.important_number;
      this.proof = value.evidence_type;
      this.workG = `${value.work_gid} - ${value.work_gname}`;
      this.department = `${value.dep_id} - ${dep_name}`;
      this.from.datenow = this.date2;
      // console.log(this.equipment_code);
    },
    async a_position(value) {
      // console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message.data);
      this.department1 = res.data.message.data[0].dep_id;
    },
    async b_position(value) {
      console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message.data);
      this.department2 = res.data.message.data[0].dep_id;
    },
    async c_position(value) {
      // console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message.data);
      this.department3 = res.data.message.data[0].dep_id;
    },
    async fullnameFromWorkgroupAndDepartment() {
      const { access_token } = await this.access_token();
      const url = `${API}fullnameFromWorkgroupAndDepartment?work_gid=${this.workG.work_gname}&dep_id=${this.department.dep_name}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message.data);
      res.data.message.data.forEach(async (element) => {
        const url_b = `${API}registerWithdraw?fullname=${element.fullname}`;
        const res_b = await axios.get(url_b, head);
        console.log(res_b.data.message.data);
      });

      this.alluser_user = res.data.message.data;
    },
    async departmentLists() {
      const { access_token } = await this.access_token();
      const url = `${API}departmentLists`;
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.department_Lists = res.data.message.data;
      // console.log(res.data.message.data);
    },
    async s_evidenceTypes() {
      const { access_token } = await this.access_token();
      const url = `${API}evidenceTypes`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.proofs = res.data.message.data;
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}user?_page=1&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      const res_total = Math.ceil(Number(res.data.message.total) / 100);
      const url_link = [];
      for (let i = 1; i <= res_total; i++) {
        url_link.push(`${API}user?_page=${i}&_limit=100`);
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i], head)).data.message.data];
      }
      console.log(res_data_);
      const res_user = res_data_.map((rs) => {
        return {
          ...rs,
          firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
        };
      });
      this.alluser = res_user;
      // console.log(res.data.message);
    },
    async get_image(value) {
      console.log(value);
      this.image_p = null;

      const { access_token } = await this.access_token();
      this.token = access_token;
      const api = `${API}equipmentDetail?e_number=${value.e_number}`;

      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(api, head);
      console.log(res);
      // console.log(typeof res.data.message.data)
      // console.log(res.data.message.data[0].e_image);
      if (res.data.message.data[0].e_image != null || res.data.message.data[0].e_image != "") {
        this.image_p = res.data.message.data[0].e_image;
        // console.log(JSON.parse(this.image_p));
        this.image_p = JSON.parse(this.image_p);
        this.image_p = this.image_p;
        // console.log(this.image_p);
      } else {
        this.image_p = null;
      }
    },
    async del_data(del_data) {
      // console.log(del_data);
      console.log(this.user);
      console.log(del_data);
      Swal.fire({
        title: "คุณต้องการจะลบข้อมูลใช่หรือไม่",
        text: `เลขทะเบียน ${del_data}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },

        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          // console.log(del_data);
          const { access_token } = await this.access_token();
          const url = `${API}withdraw/${del_data}`;
          const head = {
            headers: {
              Authorization: await access_token,
            },
          };
          console.log(del_data);
          const url_a = `${API}delHistoryLog/${del_data}`;
          const res_a = await axios.delete(url_a, head);
          console.log(res_a);

          // console.log(url);
          const res = await axios.delete(url, head);
          // console.log(res);
          Swal.fire({
            icon: "success",
            title: "ลบข้อมูลสำเร็จ!",
            text: `รายการนี้ ถูกลบเเล้ว ${del_data}`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.gettable();
          this.logUser("ข้อมูล");
        }
      });
    },
    async data_edit(data_edit) {
      // console.log(data_edit);
      const { access_token } = await this.access_token();
      const url = `${API}withdraw/${data_edit.equipment_code}`;
      const data = {
        req_date: data_edit.req_date,
        budget_year: data_edit.budget_year,
        req_name: data_edit.req_name,
      };
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.put(url, data, head);
      // console.log(res);
      this.gettable();
    },

    addItem() {
      console.log(this.pickings_temp);

      this.pickings_temp.push(...this.addItemList);

      // this.pickings_temp.push(
      //   // ...this.addItemList.map((element) => {
      //   //   return {
      //   //     ...element,
      //   //     e_gid: this.selectedG.e_gid,
      //   //   };
      //   // })
      // );
      // console.log(
      //   this.addItemList.map((element) => {
      //     return {
      //       ...element,
      //       e_gid: this.selectedG.e_gid,
      //     };
      //   })
      // );
      this.pickings.push(
        ...this.addItemList.map((element) => {
          return {
            ...element,
            e_gid: this.selectedG.e_gid,
          };
        })
      );
      // console.log(this.selectedG.e_gid);
      // console.log(this.pickings);
    },
    dateField: async function (props) {
      this.$router.push({
        name: "inoutputkuruplun",
        params: {
          rowData: props.row, // or anything you want
        },
      });
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "รายการเบิกทรัพย์สิน",
        columns: [
          {
            title: "รายการเบิกทรัพย์สิน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "วัน-เดือน-ปี (วันที่เบิก)",
                field: "need_date_th",
              },
              {
                title: "เลขทะเบียน",
                field: "equipment_code",
              },
              {
                title: "กลุ่มงาน",
                field: "work_gname",
              },
              {
                title: "ผู้เบิก/ผู้บังคับบัญชา",
                field: "req_name",
              },
            ],
          },
        ],
      });
    },
    // async changeRouteA(params){
    //   console.loh(params)
    // },
    async selectionChanged(xx) {
      // console.log(xx.selectedRows);
      this.addItemList = xx.selectedRows;
      // this.pickings = xx.selectedRows;
    },
    async item() {
      const { access_token } = await this.access_token();
      const workgroups = `${API}equipmentGroups?_limit=100&_sort=1`;
      const header_workgroups = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_workgroups = await axios.get(workgroups, header_workgroups);
      // console.log(res_workgroups.data.message.data);

      this.selectedGs = res_workgroups.data.message.data.map((element) => {
        return {
          ...element,
          e_gname: `${element.e_gid} - ${element.e_gname}`,
        };
      });
    },
    async item_select(params) {
      // console.log(params.e_gid);
      this.show = true;
      const { access_token } = await this.access_token();
      this.e_gid = params.e_gid;
      const url = `${API}withdrawList?e_gid=${this.e_gid}&e_status=false&_limit=1`;
      let head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_data = await axios.get(url, head);
      this.material = [];
      const tt = Math.ceil(res_data.data.message.total / 100);
      console.log(tt);
      for (let index = 1; index <= tt; index++) {
        console.log(index);
        const url = `${API}withdrawList?e_gid=${this.e_gid}&e_status=false&_limit=100&_page=${index}`;
        const res_data = await axios.get(url, head);
        console.log(res_data);
        this.material.push(...res_data.data.message.data);
      }
      this.show = false;
      return;
      this.material = res_data.data.message.data;
      // const { e_number, serial_number } = res_data.data.message.data;
      // this.e_number = res_data.data.message.e_number
      // this.serial_number = res_data.data.message.serial_number
      // console.log(e_number, serial_number);
      this.show = false;
    },
    async material_code() {
      const { access_token } = await this.access_token();
      const url = `${API}withdraw`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const data_res = await axios.get(url, header);
      console.log(data_res);
      const { equipment_code, code_number, budget_year } = data_res.data.message.data[0];
      const materialcode = `${equipment_code}-${code_number.toString().padStart(3, "0")}-${budget_year}`;
      this.equipment_code = data_res.data.message.data[0].equipment_code_mix;
    },
    async work() {
      const { access_token } = await this.access_token();
      const workgroups = `${API}workgroups?_page=1&_limit=100&_sort=1`;
      const header_workgroups = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_workgroups = await axios.get(workgroups, header_workgroups);
      const res_amp = res_workgroups.data.message.data.map((element) => {
        return {
          ...element,
          work_gid_work_gname: `${element.work_gid} - ${element.work_gname}`,
        };
      });
      this.workGs = res_amp;
    },
    async work_select(event) {
      const { access_token } = await this.access_token();
      const m_gid = event.work_gid;
      // console.log(m_gid);
      const url = `${API}departments?work_gid=${m_gid}&_limit=100`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      // console.log(res.data.message.data);
      const res_amp = res.data.message.data.map((element) => {
        return {
          ...element,
          dep_id_dep_name: `${element.dep_id} - ${element.dep_name}`,
        };
      });
      this.departments = res_amp;

      const url_herd = `${API}headWorkgroup?work_gid=${this.workG.work_gname}`;
      const res_heard = await axios.get(url_herd, header);
      const [fullName] = res_heard.data.message.data.map((el) => {
        return {
          ...el,
          work_gid: `${el.workgroup.split("-")[0].replace(" ", "")}`,
          work_gid_work_gname: `${el.workgroup}`,
          dep_id: `${el.depname.split("-")[0].replace(" ", "")}`,
          dep_id_dep_name: `${el.depname}`,
          fullname: `${el.title}${el.firstname} ${el.lastname}`,
        };
      });
      console.log(fullName);
      // this.workG = fullName;
      this.department = fullName;
      this.req_name = fullName;
      this.req_name = [fullName];
      console.log(res_heard.data.message.data);
    },
    async getdata() {
      this.checkLogout();
      console.log(this.pickings.length);
      if (this.pickings.length === 0) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: ``,
          title: "กรุณาเลือกรายการทรัพย์สิน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      const { access_token } = await this.access_token();

      const head_checkTaken = {
        headers: {
          Authorization: await access_token,
        },
      };
      console.log(this.pickings);
      console.log(this.pickings_temp);

      const checkTaken_url = `${API}checkTakenEquipment`;
      const checkTaken_data = {
        data: JSON.stringify(this.pickings_temp),
        task: "เบิก",
      };
      const res_checkTaken = await axios.post(checkTaken_url, checkTaken_data, head_checkTaken);

      console.log(res_checkTaken);

      if (res_checkTaken.data.status === 400) {
        console.log(res_checkTaken);
        Swal.fire({
          position: "center",
          icon: "error",
          text: `${res_checkTaken.data.message}`,
          title: "ทรัพย์สินมีการเบิกเเล้ว",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      console.log(this.proof);
      console.log(this.post_data);
      console.log(this.req_name.fullname);
      console.log(this.post_data.req_name);
      console.log(this.req_name);

      const data = {
        equipment_code: this.registration_number,
        req_date: this.from.datein,
        budget_year: this.budget_year,
        important_number: this.important_number || this.post_data.important_number,
        evidence_type: this.proof ? this.proof.evidence || this.post_data.evidence_type : " ",
        action_date: this.from.datenow || this.post_data.action_date,
        work_gid: this.workG.work_gid || this.post_data.work_gid,
        dep_id: this.department.dep_id || this.post_data.dep_id,
        req_name: this.req_name.fullname || this.post_data.req_name || this.req_name[0].fullname,
        need_date: this.from.datein,
        staff1_position: " ",
        staff3_position: " ",
        equipment_code_gen: this.equipment_code_gen,
        requistion_name: this.req_name_.firstname || this.req_name_,
        status_withdraw: "รอเบิก",
      };
      console.log(data);

      if (this.equipment_code_gen == "false") {
        const url_del = `${API}withdraw/${this.registration_number}`;
        const res_del = await axios.delete(url_del, header);
        console.log(res_del);
      }

      //console.log(res_del);

      // console.log(data);
      // const gen = this.pickings.map((res) => {
      //   console.log(res);
      //   return {
      //     work_gid: this.workG.work_gid,
      //     equipment_code: this.equipment_code,
      //     e_id: res.e_id,
      //     e_number: res.e_number,
      //     serial_number: res.serial_number,
      //   };
      // });
      // console.log(gen);

      const url_a = `${API}withdraw`;

      const res_dataA = await axios.post(url_a, data, header);
      console.log(res_dataA);

      console.log(res_dataA.data.message.equipment_code);
      this.getlog = res_dataA.data.message.equipment_code;
      const url_b = `${API}withdrawList`;
      if (res_dataA.data.status === 200) {
        const res_dataB = await Promise.all(
          this?.pickings?.map(
            async (res) =>
              await axios.post(
                url_b,
                {
                  work_gid: this.workG.work_gid || this.post_data.work_gid,
                  equipment_code: res_dataA.data.message.equipment_code,
                  e_id: res.e_id,
                  e_gid: res.e_gid,
                  e_number: res.e_number,
                  serial_number: res.serial_number,
                },
                header
              )
          )
        );
        console.log(res_dataB);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "บันทึก",
          showConfirmButton: false,
          timer: 1500,
        });
        this.pickings = [];
        this.logUser("เบิกทรัพย์สิน");
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `ผิดพลาด`,
          showConfirmButton: false,
          timer: 1500,
        });
      }

      this.gettable();
    },
    onRowSelected(getitems) {
      this.selected = getitems;
    },
    onRowlist(getlist) {
      this.listget = getlist;
    },
    async gettable() {
      // console.log(this.status_user);
      // console.log(this.username_user);
      this.show = true;
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url1 = `${API}filterTaskByUser?username=${this.username_user}&event_name=เบิกทรัพย์สิน`;
      const res1 = await axios.get(url1, header);
      const data_query = res1.data.message.data.map((el) => {
        return {
          ...el,
          equipment_code: el.task_code,
        };
      });

      const url = `${API}withdrawHistory?_page=${this.currentPage}&_limit=${this.pageLength}&_sort=-1`;
      const data = await axios.get(url, header);
      const main_data = data.data.message.data;
      this.total = data.data.message.total;
      console.log(data.data.message);
      if (this.status_user == "ผู้ใช้ทั่วไป") {
        this.show = false;
        const data_querys = data_query.map((el) => el.equipment_code);
        const data_a = main_data.filter((el) => data_querys.includes(el.equipment_code));
        // console.log(';asdasdasdasdasdasd');
        this.rows = data_a.map((element) => {
          return {
            ...element,
            need_date_th: `${parseInt(element.need_date.split("-")[2])} ${
              this.month[parseInt(element.need_date.split("-")[1])]
            }${Number(element.need_date.split("-")[0]) + 543}`,
          };
        });
      } else {
        console.log(data.data.message.data);
        this.rows = data.data.message.data.map((element) => {
          return {
            ...element,

            need_date_th: `${parseInt(element.need_date.split("-")[2])} ${
              this.month[parseInt(element.need_date.split("-")[1])]
            }${Number(element.need_date.split("-")[0]) + 543}`,
          };
        });
        this.show = false;
      }
    },
    handlePagechange(event) {
      this.currentPage = event;
      this.gettable();
    },
    handleChangePage(event) {
      this.pageLength = event;
      this.gettable();
    },
    async logUser(text) {
      // console.log(this.user);
      const { access_token } = await this.access_token();
      const url = `${API}addHistoryLogs`;
      const data = {
        username: this.user,
        event_name: `${text} ${this.getlog} `,
      };
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.post(url, data, head);
      // console.log(res.data.message);
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
